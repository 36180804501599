<template>
  <div class="white-background" id="about-dear-mama">
    <v-row class="text-center d-flex justify-center">
      <v-col class="mt-6 mb-10" cols="10">
        <h1 class="mt-5 mb-1 display-1">Dear Mama: il documentario</h1>

        <p class="font-weight-light title mt-3">
          In Italia sono circa 15.000 i minorenni accolti in servizi residenziali. Alcuni di loro non vedranno realizzato
          il sogno di trovare una famiglia ed altri, peggio, ricorderanno con
          dolore un fallimento adottivo. Molti trovano invece dei genitori per
          sempre.
        </p>
        <p class="font-weight-light title">
          Il documentario Dear Mama racconta tutto questo dal punto di vista di
          coloro che lo hanno vissuto e lo vivono ogni giorno in prima persona,
          con le loro stesse parole intrise di emozioni e con tutta la loro
          forza.
        </p>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "AboutSection",
};
</script>

<style>
.white-background {
  background-color: #ffffff !important;
}
</style>

